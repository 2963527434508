import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
// import homebg from "assets/img/mainSVG.svg";
import Navbar from "components/navbar/navbar";
import ProgressBar from "components/progress";
import "./Header.scss";
import Bunner from "components/sliders/bunner";
import useWindowSize from "hooks/useWindowSize";

function Home() {
  const [isSticky, setIsSticky] = useState(false);
  const { width: screen } = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(screen <= 474 && offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screen]);

  return (
    <>
      <div className="home">
        {/* <SVG src={homebg} className="" /> */}
        <h1 className="home-name"> Портал «Окно в мир»</h1>
        <marquee style={{ color: "#fff", fontSize: "40px" }}>
          При поддержке учреждения образования «Новополоцкий государственный
          политехнический колледж»
        </marquee>
        <Bunner />
      </div>
      <div
        className="home-navbar"
        style={{
          backgroundColor: isSticky ? "#38A169" : "transparent",
          position: isSticky ? "initial" : "sticky",
          top: 0,
        }}
      >
        <Navbar />
        <ProgressBar />
      </div>
    </>
  );
}

export default Home;

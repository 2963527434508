import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

import DoctorIMG from "assets/img/registratura.jpg";
import ShopIMG from "assets/img/shop/enter.jpg";
import TerrCenterIMG from "assets/img/terr_center.jpg";
import PoliklinikaIMG from "assets/img/poliklinika.jpg";
import BankIMG from "assets/img/BankIMG.png";
import AvariiIMG from "assets/img/avarii/avarii.jpg";

import "./trainingCard.scss";

export default function TrainingCard() {
  return (
    <div className="trainingCard">
      <Link to="/trainings/apteka">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={DoctorIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Аптека
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Аптека" может быть использован в
                  изучении алгоритма посещения данного объекта
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
      <Link to="/trainings/shop">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={ShopIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Магазин
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Магазин" может быть использован в
                  изучении алгоритма посещения данного объекта
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
      <Link to="/trainings/soc-center">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={TerrCenterIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Посещение НТЦСОН
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Посещение НТЦСОН" может быть использован
                  в изучении алгоритма посещения данного объекта
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
      <Link to="/trainings/doctors">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={PoliklinikaIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Поликлиника
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Поликлиника" может быть использован в
                  изучении алгоритма посещения данного объекта
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
      <Link to="/trainings/bank">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={BankIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Банк
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Банк" может быть использован в
                  изучении алгоритма посещения данного объекта
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
      <Link to="/trainings/avarii">
        <Card sx={{ maxWidth: 345 }} style={{ height: "100%" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={AvariiIMG}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Чрезвычайные ситуации
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span>
                  Электронный тренажёр "Чрезвычайные ситуации" может быть использован в
                  изучении алгоритма обращения за помощью в аварийные службы
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </div>
  );
}

import React from "react";
import image from "assets/img/United-Nations-PD1-transformed.png";
import pdf from "assets/pdf/situatpomosh.pdf";

function situatPomosh() {
  return (
    <div style={{ margin: "2.5% 3.5%", overflow: "hidden" }}>
      <header style={{ display: "flex", alignItems: "center" }}>
        <img src={image} style={{ maxWidth: "10%" }}></img>
        <h1 style={{ textAlign: "center" }}>
          Оказание ситуационной помощи инвалидам
        </h1>
      </header>
      <div style={{ paddingBottom: "2.5%" }}></div>
      {/* <embed src={pdf} type='application/pdf' width='100%' height='720px' /> */}
      <span
        style={{
          fontWeight: "400",
          fontSize: "125%",
          textAlign: "left",
          display: "flex",
        }}
      >
        В целях реализации Закона Республики Беларусь от 30 июня 2022 г. № 183-З
        «О правах инвалидов и их социальной интеграции», постановления Совета
        Министров Республики Беларусь от 18 октября 2022 г. № 701 «Об изменении
        постановления Совета Министров Республики Беларусь от 13 июня 2017 г. №
        451», Правил обеспечения доступности для инвалидов объектов социальной,
        транспортной и производственной инфраструктуры, транспортных средств и
        оказываемых услуг, оценки уровня их доступности, утвержденных
        постановлением Совета Министров Республики Беларусь от 21 ноября 2022 г.
        № 796, в Государственном учреждении «Новополоцкий территориальный центр
        социального обслуживания населения», при необходимости оказывается
        ситуационная помощь инвалидам. Инвалиду, не имеющему возможности
        самостоятельного передвижения и ориентирования,сопровождаю щему его
        лицу, с целью получения какой-либо услуги или информации,
        предоставляемой учреждением, рекомендуется предварительно сообщить об
        этом в Новополоцкий ТЦСОН. При обращении необходимо указать характер и
        объем требуемой ситуационной помощи. Приём звонков на оказание
        ситуационной помощи осуществляется сотрудниками отделения первичного
        приёма, информации, анализа и прогнозирования с понедельника по пятницу
        с 8.00 до 17.00 (обед с 13.00 до 14.00) по номеру телефона: 8 (0214)
        52-67-68.
      </span>
    </div>
  );
}

export default situatPomosh;

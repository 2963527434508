import React from "react";
import Training from "components/trainings";

function Home() {
  return (
    <div>
      <Training />
    </div>
  );
}

export default Home;

import React from "react";
import TrainingCard from "./trainingCard";
import News from "../news";
import Contacts from "../../pages/contacts";
function index() {
  return (
    <div>
      <p
        style={{
          fontSize: "32px",
          textAlign: "center",
          fontWeight: "600",
          color: "rgb(56, 161, 105)",
        }}
      >
        Тренажеры
      </p>
      <TrainingCard />
      <p
        style={{
          fontSize: "32px",
          textAlign: "center",
          fontWeight: "600",
          color: "rgb(56, 161, 105)",
        }}
      >
        Фотофакт
      </p>
      <News />
      <p
        style={{
          fontSize: "32px",
          textAlign: "center",
          fontWeight: "600",
          color: "rgb(56, 161, 105)",
        }}
      >
        Контакты
      </p>
      <Contacts />
    </div>
  );
}

export default index;

import React from 'react';

function SoundButton( {soundFile}) {
    const playSound = () => {
      const audio = new Audio(soundFile); // Создайте новый аудиоэлемент
      audio.play(); // Воспроизведите звук
    };
}

export default SoundButton;

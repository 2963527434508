import React from "react";
import Card from "./card";
import "./news.scss";

function news() {
  const news = {
    imageUrl: "https://i.yapx.ru/XFfRK.jpg",

  };
  const news_2 = {
    imageUrl: "https://i.yapx.ru/XFfS8.jpg",
  };
  const news_3 = {
    imageUrl: "https://i.yapx.ru/XFfT3.jpg",
  };

  return (
    <div className="news">
      <Card {...news} />
      <Card {...news_2} />
      <Card {...news_3} />
    </div>
  );
}

export default news;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./bunner.scss";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Autoplay, Navigation } from "swiper/modules";

import Center1 from "assets/img/center/1.jpg";
import Center2 from "assets/img/center/2.jpg";
import Center3 from "assets/img/center/3.jpg";
import SVG from "react-inlinesvg";

export default function App() {
  return (
    <div className="bunner">
      <Swiper
        pagination={true}
        slidesPerView={1}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Center1} alt="" className="bunner-img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Center2} alt="" className="bunner-img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Center3} alt="" className="bunner-img" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

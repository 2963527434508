import React from "react";
import "./navbar.scss";
import Menus from "./menu";
import { Link } from "react-router-dom";
import { tabsLinks } from "./tabslinks";
import { Button } from "@mui/material";

function Navbar() {
  return (
    <div className="navbar">
      {tabsLinks.map((tab, index) =>
        Array.isArray(tab.route) ? (
          <Menus
            key={index}
            label={tab.label}
            route={tab.route}
            tabsLinks={tab.route}
          />
        ) : (
          <Link to={tab.route} key={index}>
            <Button color="white">{tab.label}</Button>
          </Link>
        )
      )}
    </div>
  );
}

export default Navbar;

import React from "react";
import image from "assets/img/United-Nations-PD1-transformed.png";
import pdf from "assets/pdf/situatpomosh.pdf";

function matPomosh() {
  return (
    <div style={{ margin: "2.5% 3.5%", overflow: "hidden" }}>
      <header style={{ display: "flex", alignItems: "center" }}>
        <img src={image} style={{ maxWidth: "10%" }}></img>
        <h1 style={{ textAlign: "center" }}>Оказание материальной помощи</h1>
      </header>
      <div style={{ paddingBottom: "2.5%" }}></div>
      {/* <embed src={pdf} type='application/pdf' width='100%' height='720px' /> */}
      <span
        style={{
          fontWeight: "400",
          fontSize: "125%",
          textAlign: "left",
          display: "flex",
        }}
      >
        Перечень документов для получения гуманитарной помощи для многодетных
        семей: <br></br>• ксерокопия паспорта (31-33 стр. и регистрация);
        <br></br>• ксерокопия удостоверения многодетной семьи; <br></br>•
        ксерокопии свидетельств о рождении детей;<br></br>• справка о месте
        жительства и составе семьи (ул. Молодежная 135А);<br></br>• справка о
        доходах за 3 месяца, предшествующих месяцу обращения (на всех
        трудоспособных членов семьи)<br></br>
        <br></br>
        Перечень документов для инвалидов, неработающих пенсионеров <br></br>•
        ксерокопия паспорта;<br></br>• ксерокопия удостоверения об инвалидности
        / пенсионного удостоверения;<br></br>• справка о месте жительства и
        составе семьи (ул. Молодежная 135);<br></br>• справка о доходах за 3
        месяца, предшествующих месяцу обращения (горисполком каб.119А, 127)
        <br></br>
        <br></br>
        Перечень документов для лиц, возвратившихся из МЛС, ЛТП<br></br>•
        ксерокопия паспорта (31-33 стр. и регистрация);<br></br>• копия справки
        об освобождении;<br></br>• справка о месте жительства и составе семьи
        (ул. Молодежная 135);<br></br>• справка о доходах за 3 месяца,
        предшествующих месяцу обращения; <br></br> <br></br>
        Справочно: если гражданин не работает и не состоит на учете в «Центре
        занятости», то для подтверждения отсутствия доходов необходимо
        предоставить ксерокопию 1-ой и последней страницы трудовой книжки
        неработаютающего гражданина.
      </span>
    </div>
  );
}

export default matPomosh;

import React from "react";
import "assets/css/styles.scss";
import "assets/css/normalize.css";

import cx from "classnames";
import { Route, Routes } from "react-router-dom";
import grass from "assets/img/icons/grass.svg";

import Header from "pages/Header";
import Home from "pages/Home";
import News from "components/news";
import Contacts from "./pages/contacts";

import backImg from "assets/img/blob-scene-haikei.svg";
import SVG from "react-inlinesvg";

import KonventOPravInval from "./pages/KonventOPravInval";
import NacPlan20172025 from "./pages/NacPlan20172025";
import PostanovlenieOGosReestreTehSredstv from "./pages/PostanovlenieOGosReestreTehSredstv";
import PostanovlenieOMezved from "./pages/PostanovlenieOMezved";
import Audiobiblioteka from "./pages/Audiobiblioteka";
import GosPomosh from "./pages/GosPomosh";
import GosLgoti from "./pages/ZakonOGosSocLgotah";
import OPravahInvalidovSicIntegraciya from "./pages/OPravahInvalidovISocIntegracii";
import OPosobiiInvalid1Group80let from "./pages/OPosobiiInvalid1Group80let";
import SituatPomosh from "./pages/SituatPomosh";
import MatPomosh from "./pages/MatPomosh";
import Trenazher2 from "./components/trainings/Trenazher2";
import AptekaTrenazher from "./components/trainings/Aptekatrenazher.jsx";
import SocCenter from "./components/trainings/SocCenter";
import Shop from "./components/trainings/shop";
import Bank from "./components/trainings/Bank";
import Algoritm from "pages/Algoritm";
import LgotiSInval from 'pages/LgotiSInval'
import Avarii from "./components/trainings/Avarii";
import Putevoditel from "./pages/Putevoditel";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="home-container">
        {/* {Array.from(
          [...Array(20).keys()].map((idx) => (
            <SVG src={grass} className={cx("grass", "grass" + (idx + 1))}></SVG>
          ))
        )} */}
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route
              path="/konvent-o-prav-inval"
              element={<KonventOPravInval />}
            />
            <Route path="/nac-plan-2017-2025" element={<NacPlan20172025 />} />
            <Route
              path="/postanovlenie-o-tech-sredstvah"
              element={<PostanovlenieOGosReestreTehSredstv />}
            />
            <Route
              path="/postanovlenie-sm-rb-o-vzaimodeistvii"
              element={<PostanovlenieOMezved />}
            />
            <Route
              path="/audiobiblioteka-dlya-nezryachikh"
              element={<Audiobiblioteka />}
            />
            <Route path="/zakon-o-gos-soc-pomoschi" element={<GosPomosh />} />
            <Route path="/zakon-o-soc-lgotah" element={<GosLgoti />} />
            <Route
              path="/zakon-o-prav-inv"
              element={<OPravahInvalidovSicIntegraciya />}
            />
            <Route
              path="/postanovlenie-sm-rb-o-posobii"
              element={<OPosobiiInvalid1Group80let />}
            />
            <Route path="/sit-pomosh-invalidam" element={<SituatPomosh />} />
            <Route path="/mat-pomosh" element={<MatPomosh />} />
            <Route path="/trainings/doctors" element={<Trenazher2 />} />
            <Route path="/trainings/apteka" element={<AptekaTrenazher />} />
            <Route path="/trainings/soc-center" element={<SocCenter />} />
            <Route path="/trainings/shop" element={<Shop />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/trainings" element={<Home />} />
            <Route path="/trainings/bank" element={<Bank />} />
            <Route path="/algoritm-posesheniya" element={<Algoritm />} />
            <Route path="/lgoti-ludyam-s-invalidnostyu" element={<LgotiSInval />} />
            <Route path="/trainings/avarii" element={<Avarii />} />
            <Route path="/putevoditel" element={<Putevoditel />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
